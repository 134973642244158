footer {
  background-color: #1a2902;
  padding-top: 100px;

  .footerTop {
    .flex-div {
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: 786px) {
        flex-wrap: wrap;
      }

      .logoDiv {
        width: 50%;

        @media only screen and (max-width: 786px) {
          width: 100%;
          text-align: center;
        }

        .text-div {
          margin-top: 25px;
          max-width: 427px;
          @media only screen and (max-width: 786px) {
            max-width: unset;
          }

          p {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 25px;
            letter-spacing: 0.21px;
          }
        }

        .socioIcons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 18px;
          margin-top: 15px;

          @media only screen and (max-width: 786px) {
            justify-content: center;
          }

          .Socio {
            display: flex;
            align-items: center;
            column-gap: 5px;

            .socioIconDiv {
              width: 36px;
              height: 36px;
              flex-shrink: 0;
              border: 1px solid #fff;
              border-radius: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              overflow: hidden;
              cursor: pointer;

              &::after {
                content: "";
                position: absolute;
                top: -110%;
                left: -210%;
                width: 200%;
                height: 200%;
                opacity: 0;
                transform: rotate(30deg);

                background: rgba(255, 255, 255, 0.13);
                background: linear-gradient(
                  to right,
                  rgba(255, 255, 255, 0.13) 0%,
                  rgba(255, 255, 255, 0.13) 77%,
                  rgba(255, 255, 255, 0.5) 92%,
                  rgba(255, 255, 255, 0) 100%
                );
              }

              &:hover::after {
                opacity: 1;
                top: -30%;
                left: -30%;
                transition-property: left, top, opacity;
                transition-duration: 0.7s, 0.7s, 0.15s;
                transition-timing-function: ease;
              }

              svg {
                fill: #fff;

                path {
                  fill: #fff;
                }
              }
            }
          }
        }
      }

      .footerLinksDiv {
        display: flex;
        justify-content: space-between;
        width: 45%;
        margin-top: 50px;

        @media only screen and (max-width: 786px) {
          width: 80%;
          column-gap: 20px;
          margin-left: auto;
          margin-right: auto;
        }

        .linkDiv {
          width: 50%;

          h6 {
            color: #fff;
            font-size: 19px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: -0.38px;
            position: relative;
            padding-bottom: 11px;

            @media only screen and (max-width: 786px) {
              width: 100%;
              // text-align: center;
            }

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 45px;
              height: 2px;
              background: #fff;

              // @media only screen and (max-width: 786px) {
              //   left: 50%;
              //   transform: translate(-50%, 0);
              // }
            }
          }

          ul {
            margin-top: 7px;
            @media only screen and (max-width: 786px) {
              padding-left: 1.2rem;
            }
            li {
              &::marker {
                color: #fff;
              }

              a {
                text-decoration: none;
                color: #fff;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 37.5px;
                letter-spacing: 0.21px;
                position: relative;
                padding-bottom: 5px;

                &::after {
                  content: "";
                  position: absolute;
                  width: 0%;
                  height: 2px;
                  background-color: #fff;
                  bottom: 0;
                  left: 0;
                  transition: 0.3s;
                }

                &:hover {
                  &::after {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .footerBottom {
    padding: 30px 0px;
    border-top: 1px solid #fff;
    margin-top: 30px;

    @media only screen and (max-width: 786px) {
      padding: 20px 0;
      margin-top: 20px;
    }

    p {
      text-align: center;
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 10px;
      margin: 0;

      @media only screen and (max-width: 786px) {
        line-height: 130%;
      }
    }
  }
}

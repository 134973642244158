.privacy-policy-maindiv {
  background-color: #f7f8fa;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 40px; */
  /* margin-bottom: 40px; */
  flex-direction: column;
}

.policy-heading-container {
  background-color: #344c11;
  /* width: 57%; */
  padding: 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.policy-heading-container h3 {
  color: #fff;

  font-size: 26px;
  font-weight: 700;
}

.policy-heading-container h6 {
  color: #fff;

  font-size: 14px;
  font-weight: 500;
}

.privacy-policy-div {
  background-color: white;
  padding: 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 20px;
}

.privacy-policy {
  width: 60%;
  border-radius: 10px;
  margin-top: 40px;
}

.privacy-policy-div h6 {
}
.privacy-policy-div p {
}

@media screen and (max-width: 1024px) {
  .privacy-policy {
    width: 60%;
  }
}

@media screen and (max-width: 800px) {
  .privacy-policy {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .privacy-policy {
    width: 85%;
  }
}

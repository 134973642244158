.subscriptionScreenMain {

    padding: 50px 0;
    padding-top: 0;

    .pricing-table-subtitle {
        font-weight: normal;
    }

    .pricing-table-title {
        font-weight: bold;
    }

    .pricing-card {
        border: none;
        border-radius: 10px;
        margin-bottom: 40px;
        text-align: center;
        -webkit-transition: all 0.6s;
        transition: all 0.6s;
        margin-top: 50px;

        &:hover {
            box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
            -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
        }

        &.pricing-card-highlighted {
            box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
        }

        .card-body {
            padding-top: 55px;
            padding-bottom: 62px;
        }

        .promo-card-body {
            padding-top: unset !important;
            padding-bottom: 15px;
        }
    }

    .pricing-plan-title {
        color: #333333;
        margin-bottom: 11px;
        font-weight: normal;
    }

    .pricing-plan-cost {
        color: #333333;
        font-weight: bold;
        margin-bottom: 29px;
    }

    .sales {
        color: #d70a0a !important;
    }

    .pricing-plan-icon {
        display: inline-block;
        width: 40px;
        height: 40px;
        font-size: 40px;
        line-height: 1;
        margin-bottom: 24px;
    }

    .pricing-plan-pro {
        .pricing-plan-icon {
            color: #344c11;
        }

        .pricing-plan-purchase-btn {
            background-color: #344c11;
            color: #fff;

            &:hover {
                box-shadow: 0 3px 0 0 #344c11;
            }

            &:active {
                -webkit-transform: translateY(3px);
                transform: translateY(3px);
                box-shadow: none;
            }
        }
    }

    .pricing-plan-features {
        list-style: none;
        padding-left: 0;
        font-size: 14px;
        line-height: 2.14;
        margin-bottom: 35px;
        color: #303132;
    }

    .pricing-plan-purchase-btn {
        color: #333333;
        font-size: 16px;
        font-weight: bold;
        width: 145px;
        height: 45px;
        border-radius: 5px;
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
        position: relative;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        -webkit-box-pack: center;
        justify-content: center;
    }

}
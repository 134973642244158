.homeMain {
  .bg-div {
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: -70px;
      right: 0;
      background-image: url(../../assets/images/bgTop.png);
      background-size: contain;
      background-position: right;
      background-repeat: no-repeat;
      z-index: 1;
    }
  }

  .sectionHero {
    height: calc(100vh - 130px);
    background-color: rgba(174, 198, 112, 0.2);
    background-image: url(../../assets/images/herobg.png);
    background-size: contain;
    background-position: right bottom;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 786px) {
      padding: 30px 0;
      height: auto;
      background-image: none;

      .heading {
        text-align: center;

        br {
          display: none;
        }
      }
    }

    .text-div {
      width: 510px;
      margin-top: 8px;

      @media only screen and (max-width: 786px) {
        width: 100%;
        text-align: center;
      }

      p {
        text-align: left;
        color: #333;

        @media only screen and (max-width: 786px) {
          width: 100%;
          text-align: center;
        }
      }
    }

    .searchDiv {
      margin-top: 24px;

      form {
        display: flex;
        align-items: center;
        padding: 5px;
        background-color: #fff;
        width: max-content;
        border-radius: 5px;
        column-gap: 6px;

        @media only screen and (max-width: 786px) {
          flex-direction: column;
          width: 100%;
          row-gap: 10px;
        }

        .inputDiv {
          select {
            width: 222px;
            height: 43px;
            flex-shrink: 0;
            border-radius: 5px;
            background: rgba(174, 198, 112, 0.3);
            border: none;
            outline: none;
            padding: 0 18px;

            @media only screen and (max-width: 786px) {
              width: 100%;
            }
          }

          input {
            width: 258px;
            height: 43px;
            flex-shrink: 0;
            border-radius: 5px;
            background: rgba(174, 198, 112, 0.3);
            border: none;
            outline: none;
            padding: 0 18px;

            @media only screen and (max-width: 786px) {
              width: 100%;
            }
          }
        }

        .btn-div {
          @media only screen and (max-width: 786px) {
            width: 100%;
          }

          button {
            width: 137px;
            height: 43px;

            @media only screen and (max-width: 786px) {
              width: 100%;
            }
          }
        }
      }
    }

    .popuCategory {
      margin-top: 24px;

      @media only screen and (max-width: 786px) {
        width: 100%;
      }

      h5 {
        color: #152525;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.3px;
      }

      .tags {
        display: flex;
        column-gap: 10px;
        margin-top: 10px;

        @media only screen and (max-width: 786px) {
          flex-wrap: wrap;
          row-gap: 10px;
        }

        div {
          border-radius: 5px;
          border: 1px solid rgba(91, 58, 169, 0.46);
          padding: 0px 20px;
          color: rgba(91, 58, 169, 0.64);
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: -0.22px;
          transition: 0.3s;
          cursor: pointer;

          &:hover {
            color: #fff;
            border-color: #344c11;
            background-color: #344c11;
          }
        }
      }
    }
  }

  .section1 {
    background-color: #fff;
    position: relative;
    z-index: 2;
    padding: 66px 0;

    @media only screen and (max-width: 786px) {
      padding: 30px 0;
    }

    .totalCard {
      border-radius: 11px;
      background: #fff;
      box-shadow: 0px 0px 10px 5px #fbf2ff;
      padding: 40px 0;
      text-align: center;

      @media only screen and (max-width: 786px) {
        margin: 10px 0;
      }

      h1 {
        color: #1a2902;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 1.2px;
        margin: 0;
      }

      p {
        color: #1a2902;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.51px;
        text-transform: uppercase;
        margin: 0;
        margin-top: 20px;
      }
    }
  }

  .section2 {
    padding: 50px 0;
    padding-bottom: 140px;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 600px) {
      padding: 50px 0;
    }

    .row {
      align-items: center;
    }

    .img-div {
      img {
        width: 100%;
      }
    }

    .contentDiv {
      padding-left: 50px;

      @media only screen and (max-width: 600px) {
        padding: 0;
      }

      .heading {
        @media only screen and (max-width: 600px) {
          text-align: center;
        }

        h6 {
          margin-bottom: 17px;

          @media only screen and (max-width: 600px) {
            margin-bottom: 5px;
          }
        }

        h2 {
          position: relative;
          padding-bottom: 30px;

          @media only screen and (max-width: 600px) {
            padding-bottom: 15px;
          }

          &::after {
            content: "";
            width: 78px;
            height: 4px;
            flex-shrink: 0;
            border-radius: 14px;
            background: #344c11;
            position: absolute;
            bottom: 0;
            left: 0;

            @media only screen and (max-width: 600px) {
              left: 50%;
              transform: translate(-50%, 0);
              width: 50%;
            }
          }
        }
      }

      .text-div {
        width: 563px;
        margin-top: 20px;

        @media only screen and (max-width: 600px) {
          width: 100%;
          text-align: center;
        }
      }

      .btn-div {
        margin-top: 20px;

        @media only screen and (max-width: 600px) {
          width: 100%;
          text-align: center;
        }

        button {
          width: 198px;
          height: 40px;
        }
      }
    }
  }

  .section3 {
    padding: 50px 0;
    padding-bottom: 140px;

    @media only screen and (max-width: 600px) {
      padding-top: 0;
      padding-bottom: 50px;

      .row {
        flex-direction: column-reverse;
      }
    }

    .contentDiv {
      padding-left: 0;
      padding-right: 50px;

      @media only screen and (max-width: 600px) {
        padding: 0;

        .row {
          flex-direction: column-reverse;
        }
      }
    }
  }

  .section4 {
    padding: 70px 0;
    background-color: rgba(91, 58, 169, 0.05);

    @media only screen and (max-width: 600px) {
      padding: 50px 0;
    }

    .heading {
      margin-bottom: 120px;

      @media only screen and (max-width: 600px) {
        margin-bottom: 30px;
      }

      h2 {
        text-align: center;
        position: relative;
        padding-top: 30px;

        @media only screen and (max-width: 600px) {
          padding-top: 15px;
        }

        &::after {
          content: "";
          width: 58px;
          height: 4px;
          flex-shrink: 0;
          border-radius: 14px;
          background: #344c11;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);

          @media only screen and (max-width: 600px) {
          }
        }
      }
    }

    .row {
      row-gap: 18px;
    }
  }

  .section5 {
    padding: 150px 0;

    @media only screen and (max-width: 600px) {
      padding: 50px 0;
    }

    .text-div {
      max-width: 799px;
      margin: auto;
      margin-top: 8px;

      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .heading {
      h2 {
        text-align: center;
        position: relative;
        padding-top: 30px;

        @media only screen and (max-width: 600px) {
          padding-top: 15px;
        }

        &::after {
          content: "";
          width: 58px;
          height: 4px;
          flex-shrink: 0;
          border-radius: 14px;
          background: #344c11;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }

    .sliderDiv {
      margin: auto;
      margin-top: 45px;
      width: calc(100% - 120px);

      div {
        text-align: center;
      }

      .helpingHandCard {
        width: 95% !important;
        margin: auto;
      }

      .slick-next {
        width: 50px;
        height: 32px;
        border-radius: 236px;
        background: #344c11;
        z-index: 9;
        right: -60px;

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          background-image: url(../../assets/images/arrownext.png);
          position: absolute;
          top: 0;
          right: 0;
          background-size: 19px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      .slick-prev {
        width: 50px;
        height: 32px;
        border-radius: 236px;
        background: #344c11;
        z-index: 9;
        left: -60px;

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          background-image: url(../../assets/images/arrowprev.png);
          position: absolute;
          top: 0;
          right: 0;
          background-size: 19px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      .slick-dots {
        display: flex !important;
        align-items: center;
        justify-content: center;
        bottom: -60px;

        li {
          width: 10px;
          height: 10px;
          margin: 0 3px;

          button {
            width: 10px;
            height: 10px;
            padding: 0;

            &::before {
              width: 10px;
              height: 10px;
              content: "";
              background-color: rgba(52, 76, 17, 0.41);
              border-radius: 50px;
              opacity: 1;
            }
          }

          &.slick-active {
            width: 14px;
            height: 14px;

            button {
              width: 14px;
              height: 14px;

              &::before {
                width: 14px;
                height: 14px;
                background-color: #344c11;
              }
            }
          }
        }
      }
    }
  }

  .section6 {
    padding-bottom: 130px;

    @media only screen and (max-width: 600px) {
      padding: 50px 0;
      padding-top: 60px;
    }

    .text-div {
      // width: 897px;
      margin: auto;
      margin-top: 12px;

      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .heading {
      h2 {
        text-align: center;
        position: relative;
        padding-top: 30px;

        &::after {
          content: "";
          width: 58px;
          height: 4px;
          flex-shrink: 0;
          border-radius: 14px;
          background: #344c11;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }

    .categoryDivMain {
      margin-top: 50px;

      .row {
        row-gap: 20px;
      }

      .categoryDiv {
        width: 272px;
        height: 255px;
        border-radius: 5px;
        background-color: rgba(52, 76, 17, 0.33);
        display: flex;
        align-items: center;
        justify-content: center;
        // background-size: 110%;
        background-position: center;
        position: relative;
        overflow: hidden;
        background-repeat: no-repeat;
        cursor: pointer;

        @media only screen and (max-width: 1024px) {
          width: 215px;
        }
        @media only screen and (max-width: 600px) {
          width: 245px;
        }
        @media only screen and (max-width: 500px) {
          width: 100%;
        }

        &::after {
          content: "";
          position: absolute;
          top: -110%;
          left: -210%;
          width: 200%;
          height: 200%;
          opacity: 0;
          transform: rotate(30deg);

          background: rgba(255, 255, 255, 0.13);
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.13) 0%,
            rgba(255, 255, 255, 0.13) 77%,
            rgba(255, 255, 255, 0.5) 92%,
            rgba(255, 255, 255, 0) 100%
          );
        }

        &:hover::after {
          opacity: 1;
          top: -30%;
          left: -30%;
          transition-property: left, top, opacity;
          transition-duration: 0.7s, 0.7s, 0.15s;
          transition-timing-function: ease;
        }
      }
    }
  }
}

.btn-div {
  column-gap: 10px;

  button {
    min-width: 104px;
    height: 33px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #344c11;
    border: none;
    outline: none;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 1px solid transparent;
    transition: 0.3s;

    &:hover {
      background-color: #fff;
      border-color: #344c11;
      color: #152525 !important;
    }

    &.secondary {
      background-color: red;

      &:hover {
        background-color: #fff;
        border-color: #000;
        color: #152525 !important;
      }
    }

    &.outline {
      background-color: transparent;
      color: #000 !important;
      border: 1px solid #344c11 !important;

      &:hover {
        background-color: #507c0842;
        border-color: #000;
        color: #152525 !important;
      }
    }
  }
}
.sidebar-btn-div {
  column-gap: 10px;

  button {
    min-width: 104px;
    height: 33px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #344c11;
    border: none;
    outline: none;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 1px solid transparent;
    transition: 0.3s;

    &:hover {
      background-color: #fff;
      border-color: #344c11;
      color: #152525 !important;
    }

    &.secondary {
      background-color: red;

      &:hover {
        background-color: #fff;
        border-color: #000;
        color: #152525 !important;
      }
    }

    &.outline {
      background-color: transparent;
      color: #000 !important;
      border: 1px solid #344c11 !important;

      &:hover {
        background-color: #507c0842;
        border-color: #000;
        color: #152525 !important;
      }
    }
  }
}

.heading {
  h1 {
    color: #152525;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: -0.42px;
    margin: 0;

    span {
      color: #344c11;
    }

    @media only screen and (max-width: 600px) {
      font-size: 32px;
      line-height: 120%;
    }
  }

  h2 {
    color: #1a2902;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 45.5px;
    letter-spacing: -0.76px;
    margin: 0;

    @media only screen and (max-width: 600px) {
      font-size: 28px;
      line-height: 120%;
    }
  }

  h3 {
    color: #152525;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.45px;
    margin: 0;
  }

  h4 {
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.75);
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0.25px;
    margin: 0;
  }

  h5 {
    color: #344c11;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 135.9%;
    letter-spacing: 0.44px;
    margin: 0;

    @media only screen and (max-width: 600px) {
      font-size: 18px;
      line-height: 120%;
    }
  }

  h6 {
    color: #344c11;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 0;
  }
}

.text-div {
  p {
    color: #1a2902;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.425px;
    margin: 0;
  }
}

.jobCard {
  border-radius: 10px;
  overflow: hidden;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(119, 136, 153, 0.25);

  .cover {
    height: 134px;
    text-align: center;
    position: relative;

    .img-div {
      position: absolute;
      text-align: center;
      width: 100%;
      bottom: -40px;

      img {
        width: 129px;
        height: 129px;
        border-radius: 50%;
        border: 3px solid #152525;
        // background-color: rgb(242, 255, 222)
      }
    }
  }

  .content {
    padding: 0 15px;
    padding-top: 60px;

    @media only screen and (max-width: 600px) {
      text-align: center;
    }

    .vacancy {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tagVac {
        width: 140px;
        height: 22px;
        border-radius: 5px;
        background: rgba(91, 58, 169, 0.1);
        color: #344c11;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: -0.11px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-right: 10px;
        }
      }

      .tagCount {
        width: 46px;
        height: 22px;
        flex-shrink: 0;
        border-radius: 5px;
        background: rgba(91, 58, 169, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #344c11;
        text-align: center;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.11px;
        text-transform: uppercase;
      }
    }

    .heading {
      margin-bottom: 0 !important;
      // margin-top: 23px;

      h6 {
        color: #152525;
        line-height: 24px;
      }
    }

    .publishDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 17px;

      span {
        color: #152525;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.24px;

        &.span2 {
          color: #344c11;
        }
      }
    }

    .postedBy {
      display: flex;
      justify-content: start;
      align-items: center;

      @media only screen and (max-width: 600px) {
        justify-content: center;
      }

      span {
        color: #152525;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.24px;

        &.span2 {
          color: #0089ed;
        }
      }
    }

    hr {
      border-color: rgba(91, 58, 169, 0.37);
    }

    .pricingDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      span {
        color: #1a2902;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
      }

      .btn-div {
        button {
          width: 69px;
          height: 21px;
          color: #fff;
          text-align: center;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 10.5px;
          letter-spacing: -0.1px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.helpingHandCard {
  height: 310px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(91, 58, 169, 0.2);
  position: relative;
  overflow: hidden;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 67px);

    .img-div {
      img {
        width: 113px;
      }
    }

    .heading {
      margin-top: 20px;

      h6 {
        color: #152525;
        text-align: center;
        margin: 0;
      }

      p {
        color: #4d4d4d;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.28px;
        margin: 0;
        margin-top: 5px;
      }
    }
  }

  .bottom {
    position: absolute;
    height: 67px;
    background: rgba(91, 58, 169, 0.06);
    width: 100%;
    padding: 0 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .flex-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      span {
        display: inline-block;
        color: #1a2902;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.26px;
      }
    }
  }
}

.banner-sec {
  height: 170px;
  background-image: url(../assets/images/banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  @media only screen and (max-width: 600px) {
    height: 150px;
  }

  h1 {
    z-index: 3;
    position: relative;
  }

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(239, 244, 226, 0.22);
    z-index: 2;
  }

  // &::after {
  //     position: absolute;
  //     content: "";
  //     width: 400px;
  //     height: 240px;
  //     left: 0;
  //     bottom: 0;
  //     background: url(../assets/images/bannerAfter.png);
  //     background-position: center;
  //     background-size: contain;
  // }
}

.filterDiv {
  position: sticky;
  top: 150px;

  .selectDiv {
    label {
      font-size: 14px;
      margin-left: 10px;
    }
  }
}

.accordion-button {
  outline: none !important;
  box-shadow: none !important;
}

.accordion-header,
.accordion-button {
  background-color: rgba(174, 198, 112, 0.2) !important;
  color: #344c11;
}

.accordion-button:not(.collapsed) {
  background-color: #344c11 !important;
  color: #fff;
}

.accordion-item {
  border-radius: 5px !important;
  overflow: hidden;
  margin-bottom: 20px;
}

.blogCard {
  border-radius: 0;

  .imgDiv {
    height: 300px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 10px;
  }

  .textDiv {
    padding: 10px;

    .head1 {
      h5 {
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: #1a2902;
      }
    }

    .dateDiv {
      margin-bottom: 10px;

      p {
        font-size: 16px;
        margin: 0;
      }
    }

    .text-div {
      p {
        color: #000;
        opacity: 0.6;
        font-size: 16px;
      }
    }
  }
}

.authMain {
  height: 100vh;

  .imageDiv {
    background: linear-gradient(270deg, #8fc43a 0%, #2b4205 113.67%);
    width: 100%;
    height: 100%;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("../assets/images/loginBefore.png");
      background-size: 95%;
      background-position: center left;
      background-repeat: no-repeat;
    }
  }

  .formDiv {
    width: 70%;
    margin: auto;

    form {
      width: 100%;
      margin-top: 40px;

      .checkboxSelect {
        width: 48%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 15px;
        border: 1px solid #3b570d;
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        transition: 0.3s;

        &.active {
          background-color: #3b570d;
          color: #fff;
        }

        &:hover {
          background-color: #3b570d3f;
          color: #000;
        }

        p {
          margin: 0;
        }
      }

      .inputDiv {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;

        label {
          font-size: 16px;
          line-height: 24px;
          color: #1a2902;
          margin-bottom: 5px;
        }

        input {
          background: #ffffff;
          border: 1px solid #adadad;
          border-radius: 5px;
          padding: 0 20px;
          height: 50px;
          outline: none;
          transition: 0.3s;

          &:hover,
          &:focus {
            border-color: #3b570d;
          }
        }
      }

      .rememberMe {
        label,
        a {
          color: #0089ed;
          font-size: 14px;
          text-decoration: none;
        }
      }

      .btn-div {
        width: 100%;
        margin-top: 20px;

        button {
          width: 100%;
          height: 40px;
          font-size: 16px;
        }
      }
    }

    .formBelow {
      margin-top: 20px;

      p {
        margin: 0;
      }

      a {
        text-decoration: none;
      }
    }
  }
}

.portalLayoutMain {
}

.inputDiv {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 16px;
    line-height: 24px;
    color: #1a2902;
    margin-bottom: 5px;

    sup {
      font-weight: bold;
      color: red;
      font-size: 12px;
    }
  }

  input,
  select,
  textarea {
    background: #ffffff;
    border: 1px solid #adadad;
    border-radius: 5px;
    padding: 0 20px;
    height: 50px;
    outline: none;
    transition: 0.3s;

    &:hover,
    &:focus {
      border-color: #3b570d;
    }
  }

  textarea {
    height: auto;
    padding: 20px;
  }
}

.JobsMain {
  @media only screen and (max-width: 600px) {
    .row {
      row-gap: 20px;
    }
  }
}

.custom-pagination .page-item.active .page-link {
  background-color: #3b570d; /* Change this to your desired active color */
  color: #fff;
  border-color: #3b570d; /* Change this to your desired border color */
}

.custom-pagination .page-link {
  color: #3b570d; /* Change this to your desired link color */
}

.custom-pagination .page-link:hover {
  background-color: #3b570d; /* Change this to your desired hover color */
  color: white; /* Change this to your desired hover text color */
}

.custom-pagination .page-item.disabled .page-link {
  color: #6c757d; /* Change this to your desired disabled color */
}

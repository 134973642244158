.dDrawerMain {
  width: 100%;
  position: sticky;
  top: 10px;

  .navMain {
    margin-top: 10px;

    .nav-item {
      a {
        padding: 10px 10px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.288);
        text-decoration: none;
        width: 100%;
        display: inline-block;
        color: gray;
        &.active {
          color: #7eb426;
        }
        &.nav-link {
          &.active {
            color: gray;
          }
        }
      }

      &:last-child a {
        border: none;
      }
    }
  }

  .imgDiv {
    .bgProfile {
      margin: auto;
      width: 150px;
      height: 150px;
      background-image: url("https://script.viserlab.com/microlab/assets/images/user/profile/user.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 200px;
    }
  }

  .heading {
    i {
      color: #7eb426;
      font-size: 14px;
    }
  }
}

.chatComponentMain {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 5555;
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
  column-gap: 5px;
  @media only screen and (max-width: 786px) {
    display: none;
  }
  .allChats {
    width: 350px;
    height: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #fff;
    overflow: hidden;
    transition: 0.3s;

    .chatHeader {
      height: 50px;
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: #344c11;
      padding: 0 20px;
      position: relative;
      z-index: 5;

      h6 {
        color: #fff;
      }
     
      
    }

    .chatList {
      overflow: hidden;
      height: calc(100% - 50px);
      overflow-y: scroll;

      .list {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;

        &:hover {
          background-color: rgba(128, 128, 128, 0.171);
        }

        .imgDiv {
          width: 50px;
          border: 1px solid rgba(128, 128, 128, 0.171);
          border-radius: 5px;

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        .nameDiv {
          display: flex;
          flex-direction: column;
          padding-left: 10px;

          .heading {
            h6 {
              font-size: 16px;
            }
          }

          .text-div {
            p {
              font-size: 12px;
              line-height: 120%;
            }
          }
        }
      }
    }
  }

  .messagesDiv {
    position: relative;
    height: calc(100% - 50px);

    .messageBox {
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: 2;
      height: 50px;
      border-top: 1px solid #344c11;

      input {
        width: calc(100% - 50px);
        height: 100%;
        border: 0;
        padding: 0 20px;
        outline: none;
      }

      button {
        width: 50px;
        height: 100%;
        border: 0;
        background-color: transparent;
        outline: none;
        transition: 0.3s;

        svg {
          fill: #344c11;
        }

        &:hover {
          background-color: #344c114d;
        }
      }
    }

    .messages {
      padding: 10px;
      height: calc(100% - 50px);
      overflow-y: scroll;
      flex-direction: column;
      justify-content: end;
      scroll-behavior: smooth;

      .from {
        margin-left: 6px;
        background-color: #eef0f4;
        border: 1px solid #eef0f4;
        color: #12151b;
        margin-bottom: 8px;
        border-radius: 12px;
        display: flex;
        max-width: 75%;
        padding: 8px;
        position: relative;
        font-size: 15px;
        font-weight: 400;
      }
      .to {
        margin-left: 6px;
        background-color: #33905e;
        border: 1px solid #33905e;
        color: #fff;
        margin-bottom: 8px;
        border-radius: 12px;
        display: flex;
        max-width: 75%;
        padding: 8px;
        position: relative;
        font-size: 15px;
        font-weight: 400;
        margin-left: auto;
      }
    }
  }
  
}
.dot-indicator {
  width: 8px;
  height: 8px;
  background-color: red; // Change color as needed
  border-radius: 50%;
  margin-left: 10px; // Space between the text and the dot
  display: inline-block;
}

.modal-body {
  .modalMain {
    min-width: 500px;

    @media only screen and (max-width: 600px) {
      min-width: 100%;
    }
  }

  .errorMsg {
    text-align: center;

    small {
      font-size: 14px;
      color: red;
      font-weight: bold;
    }
  }
  .checkboxSelect {
    width: 48%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
    border: 1px solid #3b570d;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    transition: 0.3s;

    &.active {
      background-color: #3b570d !important;
      color: #fff !important;
    }

    &:hover {
      background-color: #3b570d3f !important;
      color: #000 !important;
    }

    p {
      margin: 0 !important;
      color: unset !important;
    }
  }
  .successMsg {
    text-align: center;

    small {
      font-size: 14px;
      color: green;
      font-weight: bold;
    }
  }

  .card {
    margin: 0 !important;

    .card-body {
      @media only screen and (max-width: 600px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    h5 {
      color: #232645;
      font-family: Montserrat;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      margin: 0;
      margin-bottom: 20px !important;
      @media only screen and (max-width: 600px) {
        font-size: 24px;
      }
    }

    .wrong {
      margin: 0;
      color: #000;
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: -0.198px;
      margin-top: 10px;
      display: inline-block;

      @media only screen and (max-width: 600px) {
        font-size: 12px;
      }
    }

    p {
      margin: 0;
      color: #000;
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: -0.198px;
      margin-bottom: 30px;

      @media only screen and (max-width: 600px) {
        font-size: 12px;
      }

      &.resend {
        margin-top: 10px;
        margin-bottom: 0;

        a {
          color: #f00;
        }
      }
    }
  }

  .otp-field {
    flex-direction: row;
    column-gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .otp-field input {
    height: 60px;
    width: 53px;
    border-radius: 6px;
    outline: none;
    font-size: 1.125rem;
    text-align: center;
    border: 1px solid #ddd;

    @media only screen and (max-width: 600px) {
      width: 43px;
      height: 50px;
    }
  }

  .otp-field input:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  }

  .otp-field input::-webkit-inner-spin-button,
  .otp-field input::-webkit-outer-spin-button {
    display: none;
  }

  .resend {
    font-size: 12px;
  }
}

.modal-backdrop.show {
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    filter: blur(5px);
  }
}

.modal {
  backdrop-filter: blur(4px);
}

.JobsDetailMain {
  .heading {
    display: flex;
    align-items: center;
    column-gap: 10px;
    h3 {
      margin: 0;
    }
  }
  .postdate-and-address {
    display: flex;
    width: 60%;
    justify-content: space-between;
    column-gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    @media only screen and (max-width: 1024px) {
      width: 80%;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    .address {
      display: flex;
      column-gap: 10px;
      .address-icon {
        width: 24px;
        height: 24px;
      }
    }
  }
  .descripton {
    // width: 80%;
    word-wrap: break-word; /* Allow long words to break */
    overflow-wrap: break-word; /* For modern browsers */
  }
  .time-duration-div {
    display: flex;
    width: 60%;
    justify-content: space-between;
    margin-top: 40px;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    .timing {
      display: flex;
      column-gap: 10px;
      align-items: center;
      font-weight: 600;
      margin-bottom: 0px;
      .timing-icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
/* Job CSS */

.pagination-custom {
  border-radius: 5px;
}

.pagination-custom .page-item.active .page-link {
  background-color: #3b570d;
  color: #ffffff;
  border: #3b570d;
}

.pagination-custom .page-link {
  color: #000;
}

.pagination-custom .page-link:hover {
  background-color: #3b570d;
  color: #ffffff;
}
.new-jobs-popup {
  position: fixed;
  top: 0;
  left: 36%;
  transform: translateX(-50%);
  background-color: #28a745;
  color: white;
  padding: 1rem;
  z-index: 1050;
  border-radius: 0 0 5px 5px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  animation: slideDown 0.5s ease forwards;
  cursor: pointer;
  @media only screen and (max-width: 1024px) {
    left: 33%;
  }
  @media only screen and (max-width: 768px) {
    left: 29%;
  }
  @media only screen and (max-width: 500px) {
    left: 0%;
    max-width: unset;
  }
}
@keyframes slideDown {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}

// .pagination-custom .page-item {
//   margin: 0 5px; /* Spacing between pagination items */
// }

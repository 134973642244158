.contactMain {
  @media only screen and (max-width: 600px) {
    .row {
      row-gap: 20px;
    }
  }

  .contactIntro {
    background-color: rgba(174, 198, 112, 0.2);
    padding: 30px;
    border-radius: 6px;

    .cont {
      margin-top: 30px;

      @media only screen and (max-width: 600px) {
        text-align: center;
      }

      h4 {
        font-size: 22px;
        display: flex;
        column-gap: 10px;
        align-items: center;
        font-weight: bold;
        margin-bottom: 16px;

        @media only screen and (max-width: 600px) {
          justify-content: center;
        }
      }

      p {
        margin: 0;
        max-width: 70%;

        @media only screen and (max-width: 600px) {
          max-width: 100%;
        }
      }
    }

    @media only screen and (max-width: 600px) {
      .head1 {
        text-align: center;
      }

      p {
        text-align: center;
      }
    }
  }

  .formDiv {
    padding: 30px;

    form {
      margin-top: 20px;

      .inputDiv {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        margin-bottom: 10px;

        label {
          font-size: 16px;

          sup {
            color: red;
          }
        }

        input {
          height: 50px;
          font-size: 16px;
          padding: 0 20px;
          outline: none;
          border: 1px solid rgba(128, 128, 128, 0.308);
          border-radius: 5px;

          &:focus {
            border-color: rgba(174, 198, 112, 1);
          }
        }

        textarea {
          font-size: 16px;
          padding: 0 20px;
          outline: none;
          border: 1px solid rgba(128, 128, 128, 0.308);
          border-radius: 5px;

          &:focus {
            border-color: rgba(174, 198, 112, 1);
          }
        }
      }

      .btn-div {
        width: 100%;
        margin-top: 20px;

        button {
          width: 100%;
          height: 40px;
          font-size: 16px;
        }
      }
    }
  }
}

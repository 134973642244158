.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #344c11;
  box-shadow: 0 0 3px #8798ad;
  color: #fff;
}
.rmdp-week-day {
  color: #54781e;
  cursor: default;
  font-size: 13px;
  font-weight: 500;
}
.rmdp-day.rmdp-today span {
  background-color: rgb(102, 137, 51);
  color: #fff;
}
.rmdp-panel-body li {
  background-color: #344c11;
  border: none;
  font-size: 14px;
  padding: 4px;
}
.rmdp-panel-body li .b-deselect {
  background-color: #344c11;
}
.rmdp-arrow {
  border: solid #344c11;
  border-width: 0 2px 2px 0;
  display: inline-block;
  height: 3px;
  margin-top: 5px;
  padding: 2px;
  width: 3px;
}

.rmdp-day span.sd:hover {
  background-color: #a0c667 !important;
}
.rmdp-input {
  margin-left: 12px;
}
.chk {
  width: 24px;
}
.table-responsive {
  overflow-x: auto;
}

.description-cell {
  /* white-space: normal; 
  word-wrap: break-word;
  max-width: 200px;  */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.modal-div {
  column-gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-div button {
  min-width: 104px;
  height: 33px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #344c11;
  border: none;
  outline: none;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid transparent;
  transition: 0.3s;

  &:hover {
    background-color: #fff;
    border-color: #344c11;
    color: #152525 !important;
  }

  &.secondary {
    background-color: red;

    &:hover {
      background-color: #fff;
      border-color: #000;
      color: #152525 !important;
    }
  }

  &.outline {
    background-color: transparent;
    color: #000 !important;
    border: 1px solid #344c11 !important;

    &:hover {
      background-color: #507c0842;
      border-color: #000;
      color: #152525 !important;
    }
  }
}

.reviewimg {
  height: 100px;
}

.bgtop {
  background-image: url("https://i0.wp.com/nftartwithlauren.com/wp-content/uploads/2024/01/laurenmcdonaghpereiraphoto_A_field_of_lavender_with_a_soft_su_645ac3bc-37ff-4eb0-8a8b-4d20c9c58037_1.png?fit=1024%2C574&ssl=1");
  background-size: cover;
  height: 300px;
  background-repeat: no-repeat;
}
.userimg {
  margin-top: -118px;
  margin-left: 40px;
}
.btn-divd {
  margin-left: 56px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.chatMain {
  padding: 30px 0;

  .main-chatList {
    overflow: hidden;
    height: calc(100% - 50px);
    overflow-y: scroll;
    margin-top: 10px;
    margin-bottom: 10px;
    height: calc(80vh - 100px);

    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 50px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #344c11;
      border-radius: 50px;
    }

    .list {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      border-radius: 10px;
      overflow: hidden;

      &.active{
        background-color: #33905e25;
      }

      &:hover {
        background-color: rgba(128, 128, 128, 0.171);
      }

      .imgDiv {
        width: 50px;
        border: 1px solid rgba(128, 128, 128, 0.171);
        border-radius: 5px;
        border-radius: 10px;
        position: relative;

        .badgeMessage{
          width: 15px;
          height: 15px;
          border-radius: 100%;
          background-color: #344c11;
          position: absolute;
          top: -10px;
          right: -15px;
          display: block;
          color: #fff;
          display: none;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          z-index: 4;
        }

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .nameDiv {
        display: flex;
        flex-direction: column;
        padding-left: 10px;

        .heading {
          h6 {
            font-size: 16px;
          }
        }

        .text-div {
          p {
            font-size: 12px;
            line-height: 120%;
          }
        }
      }
    }
  }

  .main-allChats {
    margin-top: 20px;
    margin-bottom: 20px;
    height: calc(80vh - 100px);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #fff;
    transition: 0.3s;
    border-radius: 10px;
    overflow: hidden;

    .chatHeader {
      height: 50px;
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: #344c11;
      padding: 0 20px;
      position: relative;
      z-index: 5;

      h6 {
        color: #fff;
      }
    }

    .chatList {
      overflow: hidden;
      height: calc(100% - 50px);
      overflow-y: scroll;

      .list {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;

        &:hover {
          background-color: rgba(128, 128, 128, 0.171);
        }

        .imgDiv {
          width: 50px;
          border: 1px solid rgba(128, 128, 128, 0.171);
          border-radius: 5px;

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        .nameDiv {
          display: flex;
          flex-direction: column;
          padding-left: 10px;

          .heading {
            h6 {
              font-size: 16px;
            }
          }

          .text-div {
            p {
              font-size: 12px;
              line-height: 120%;
            }
          }
        }
      }
    }
  }

  .messagesDiv {
    position: relative;
    height: calc(100% - 50px);

    .messageBox {
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: 2;
      height: 50px;
      border-top: 1px solid #344c11;
      

      input {
        width: calc(100% - 50px);
        height: 100%;
        border: 0;
        padding: 0 20px;
        outline: none;
      }

      button {
        width: 50px;
        height: 100%;
        border: 0;
        background-color: transparent;
        outline: none;
        transition: 0.3s;

        svg {
          fill: #344c11;
        }

        &:hover {
          background-color: #344c114d;
        }
      }
    }

    .messages {
      padding: 10px;
      height: calc(100% - 50px);
      overflow-y: scroll;
      flex-direction: column;
      justify-content: end;
      scroll-behavior: smooth;
      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 50px;
      }
  
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 50px;
      }
  
      &::-webkit-scrollbar-thumb {
        background-color: #344c11;
        border-radius: 50px;
      }

      .from {
        margin-left: 6px;
        background-color: #eef0f4;
        border: 1px solid #eef0f4;
        color: #12151b;
        margin-bottom: 8px;
        border-radius: 12px;
        display: flex;
        width: max-content;
        max-width: 75%;
        padding: 8px;
        position: relative;
        font-size: 15px;
        font-weight: 400;
      }

      .to {
        margin-left: 6px;
        background-color: #33905e;
        border: 1px solid #33905e;
        color: #fff;
        margin-bottom: 8px;
        border-radius: 12px;
        display: flex;
        width: max-content;
        max-width: 75%;
        padding: 8px;
        position: relative;
        font-size: 15px;
        font-weight: 400;
        margin-left: auto;
      }
    }
  }
}
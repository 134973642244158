.dashboardMain {
  .overViewDiv {
    height: 170px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    .heading {
      padding: 20px;
      padding-bottom: 0;

      h6 {
        color: #ffff;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 300;
      }

      h1 {
        color: #fff;
      }
    }

    .detailDiv {
      height: 40px;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.2);

      a {
        width: 100%;
        display: inline-block;
        padding: 0 20px;
        height: 100%;
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 600;
        text-decoration: none;
      }
    }

    &.balance {
      background-color: #36622e;
    }

    &.deposit {
      background-color: #70243b;
    }

    &.withdraw {
      background-color: #162616;
    }

    &.completedJobs {
      background-color: #27496a;
    }

    &.jobsCreated {
      background-color: #431a74;
    }

    &.transactions {
      background-color: #af0f0a;
    }
  }
}

.createJobMain {
  border-radius: 10px;

  .btn-div {
    button {
      width: 100%;
      height: 40px;
      font-size: 18px;
    }
  }

  .inputDiv {
    margin-bottom: 20px;
  }
}

.jobHistoryMain {
  padding-right: 0 !important;

  .searchInput {
    margin-left: auto;
    display: flex;
    align-items: center;
    width: max-content;

    input {
      height: 40px;
      padding: 0 20px;
      border: 1px solid #7eb426;
      outline: none;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .searchIconBtn {
      background-color: #7eb426;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      overflow: hidden;

      button {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        outline: none;
      }
    }
  }

  .tableDiv {
    margin-top: 30px;

    th {
      background-color: #7eb426;
      color: #fff;
      padding-left: 20px;
      font-size: 14px;
    }

    .dim {
      opacity: 0.3;
    }

    td {
      font-size: 14px;
      vertical-align: middle;

      .imgDiv {
        width: 60px;
        height: 60px;
        background-image: url("https://script.viserlab.com/microlab/assets/images/user/profile/user.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 10px;
      }
    }
  }
}

.profileMain {
  form {
    .imgDiv {
      height: 300px;
      width: 100%;
      overflow: hidden;
      background-image: url("https://script.viserlab.com/microlab/placeholder-image/350x300");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 10px;
    }

    .inputDiv {
      margin-bottom: 20px;
    }

    .btn-div {
      margin-top: 15px;

      button {
        width: 100%;
        height: 50px;
        font-size: 16px;
        position: relative;
        cursor: pointer;

        input {
          position: absolute;
          left: 0;
          opacity: 0;
          height: 100%;
          width: 100%;
          cursor: pointer;
          top: 0;
        }
      }
    }
  }
}

.idenityVerify {
  .UploadDivMain {
    margin-top: 30px;

    .uploadView {
      width: 100%;
      height: 220px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;

      .fileInput {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        top: 0;
        left: 0;

        input {
          width: 100%;
          height: 100%;
          cursor: pointer;
          z-index: 2;
        }
      }
    }

    .heading {
      h6 {
        opacity: 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
      }
    }
  }

  .textDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .btn-div {
      button {
        position: relative;

        .fileInput {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          top: 0;
          left: 0;

          input {
            width: 100%;
            height: 100%;
            cursor: pointer;
            z-index: 2;
          }
        }
      }
    }
  }

  .btn-div {
    margin-top: 20px;
    width: 100%;

    button {
      width: 100%;
      height: 40px;
      font-size: 16px;
    }
  }
}

.userProfile {
  .card {
    position: relative;
    .btn-div {
      position: absolute;
      right: 30px;
    }
  }

  .userReview{
    padding: 20px 0;
    border-bottom: 1px solid rgba(128, 128, 128, 0.418);
  }
}

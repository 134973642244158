.getStartedMain {
  margin-bottom: -40px;
  position: relative;
  z-index: 2;

  .getStartedDiv {
    max-width: 1071px;
    margin: auto;
    border-radius: 18px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 40px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 786px) {
      text-align: center;
      width: 100%;
      padding: 30px;
      flex-direction: column;
    }

    .text-div {
      max-width: 617px;
      margin-top: 30px;

      @media only screen and (max-width: 786px) {
        width: 100%;
      }
    }

    .heading {
      h3 {
        position: relative;
        padding-bottom: 30px;

        @media only screen and (max-width: 786px) {
          width: 100%;
        }

        &::after {
          content: "";
          width: 188px;
          height: 4px;
          flex-shrink: 0;
          border-radius: 14px;
          background: #344c11;
          position: absolute;
          bottom: 0;
          left: 0;

          @media only screen and (max-width: 786px) {
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
      }
    }

    .btn-div {
      @media only screen and (max-width: 786px) {
        margin-top: 20px;
      }

      button {
        width: 178px;
        height: 47px;
      }
    }
  }
}

header {
  z-index: 999;
  border-color: #fff;
  position: sticky;
  top: 0;

  .headerTop {
    height: 40px;
    background: rgb(239, 244, 226);
    display: flex;
    align-items: center;

    @media only screen and (max-width: 600px) {
      display: none;
    }

    .flexDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      .contact {
        display: flex;
        align-items: center;
        column-gap: 70px;

        .cont {
          a {
            color: #434343;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.24px;
            text-decoration: none;

            svg {
              margin-right: 15px;
            }
          }
        }
      }

      .socioIcons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 18px;

        .Socio {
          display: flex;
          align-items: center;
          column-gap: 5px;

          .socioIconDiv {
            width: 28px;
            height: 28px;
            flex-shrink: 0;
            border: 1px solid #434343;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            cursor: pointer;

            &::after {
              content: "";
              position: absolute;
              top: -110%;
              left: -210%;
              width: 200%;
              height: 200%;
              opacity: 0;
              transform: rotate(30deg);

              background: rgba(255, 255, 255, 0.13);
              background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0.13) 0%,
                rgba(255, 255, 255, 0.13) 77%,
                rgba(255, 255, 255, 0.5) 92%,
                rgba(255, 255, 255, 0) 100%
              );
            }

            &:hover::after {
              opacity: 1;
              top: -30%;
              left: -30%;
              transition-property: left, top, opacity;
              transition-duration: 0.7s, 0.7s, 0.15s;
              transition-timing-function: ease;
            }
          }
        }

        .trans {
          .dropdown {
            button {
              width: 87px;
              height: 25px;
              flex-shrink: 0;
              border: none;
              border-radius: 5px;
              background: #fff;
              box-shadow: 0px 0px 1px 0px rgba(119, 136, 153, 0.1);
              color: #344c11;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.12px;
            }

            .dropdown-item {
              color: #344c11;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.12px;
            }
          }
          @media only screen and (max-width: 768px) {
            display: none !important;
          }
        }
      }
    }
  }
  .hamburger {
    display: none; /* Hidden by default */
    flex-direction: column;
    cursor: pointer;

    span {
      height: 3px;
      width: 25px;
      background: #1a2902;
      margin: 3px 0;
      transition: 0.4s;
    }

    @media only screen and (max-width: 786px) {
      display: flex; /* Show hamburger on small screens */
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // semi-transparent background
    z-index: 998; // should be below the header but above other content
    animation: fadeIn 0.3s; // fade-in animation
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0; // Changed from right to left
    width: 250px; // or your desired width
    height: 100%;
    background-color: #fff; // sidebar background
    z-index: 999; // should be above the overlay
    animation: slideIn 0.3s; // slide-in animation
  }

  @keyframes slideIn {
    from {
      transform: translateX(-100%); // Changed to slide in from the left
    }
    to {
      transform: translateX(0);
    }
  }

  .sidebar ul {
    list-style-type: none;
    padding: 0;

    li {
      margin: 15px 15px;

      a {
        color: #1a2902;
        font-size: 18px;
        text-decoration: none;
      }
    }
  }

  @media only screen and (max-width: 786px) {
    .navlinks {
      display: none; /* Hide original navlinks */
    }
  }
  .sidebar-btn-div {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    flex-wrap: wrap;
    row-gap: 10px;
    // flex-direction: column;
  }

  .headerMain {
    padding: 10px 0;
    background-color: #fff;

    .flexDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logoDiv {
        img {
          @media only screen and (max-width: 600px) {
            width: 70px;
          }
        }
      }

      .navlinks {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 34px;

        @media only screen and (max-width: 600px) {
          display: none;
        }

        .linksDiv {
          @media only screen and (max-width: 786px) {
            display: none;
          }

          ul {
            display: flex;
            margin: 0;
            padding: 0;
            column-gap: 50px;

            li {
              display: inline-block;

              a {
                color: #1a2902;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-decoration: none;
                position: relative;
                padding-bottom: 5px;

                .badgeNew{
                  width: 15px;
                  height: 15px;
                  border-radius: 100%;
                  background-color: #344c11;
                  position: absolute;
                  top: -10px;
                  right: -15px;
                  color: #fff;
                  display: none;
                  align-items: center;
                  justify-content: center;
                  font-size: 10px;
                }

                &::after {
                  content: "";
                  position: absolute;
                  width: 0%;
                  transition: 0.3s;
                  height: 2px;
                  background-color: #1a2902;
                  bottom: 0;
                  left: 0;
                }

                &:hover {
                  &::after {
                    width: 100%;
                  }
                }

                &.active {
                  &::after {
                    width: 100%;
                  }
                }
              }
            }
          }
        }

        .btn-div {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 10px;
          @media only screen and (max-width: 786px) {
            display: none;
          }
        }
      }
    }
  }
}
